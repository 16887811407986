import React from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import Posts from "../components/posts";
import Thumbnail from "../components/thumbnail";
import BlogContent from "../components/blog-content";
import Breadcrumbs from "../components/breadcrumbs"
import Translate from "../components/translate";
import Layout from "../components/layout";
import resolveUrl from "../utils/urls/resolve-url";
import Icon from "../components/icon.tsx"

import BaseHead from "../components/base-head";
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props} 
    title={`${props.data.mysqlAuthor.name} - ekspert finansowy | loando.mx`} 
    description={props.data.mysqlAuthor.description} 
  >
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/author/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const author = ({ 
  data: { mysqlAuthor, categories }, 
  pageContext : { breadcrumbs },
  location: { pathname },
}) => {
  const {
    description,
    name,
    university,
    degree,
    education,
    social_li,
    mysqlImage,
    mysqlId,
    title,
  } = mysqlAuthor;

  const blogs = mysqlAuthor.blogs.filter(({ promo_bonus, external }) => external.length === 0 && (promo_bonus === null || promo_bonus?.trim()?.length === 0));
  
  return (
    <Layout pathname={pathname}>
      <div className={[b.container, b.pt3,'breadcrumbs-mobile',b.ptLg5,"scroll-inner"].join(' ')}>
        <Breadcrumbs theme='white' items={breadcrumbs}/>
      </div>
      <article className={["firm-page",b.pt4].join(' ')}>
        <div className={[b.container, "blog-content", "post-content"].join(" ")}>
          {mysqlImage && (
            <>
              <div className={["imgBg-box",b.dFlex,b.alignItemsCenter,b.justifyContentCenter].join(' ')}>
                <Thumbnail image={mysqlImage} className="imgBg" alt={name + ' - tło'}/>
              </div>
              <div className={"imgPortrait"}>
                <Thumbnail image={mysqlImage} alt={name}/>
              </div>
            </>
          )}
          <div className={["author-box",b.mt2, b.mb5, b.pbLg5].join(' ')}>
            <h1 className={["big-title",'blackToWhite'].join(' ')}>{name}</h1>
            
            {title && (
              <div className="div-author">
                <span className={"author-blue-span"}>{title}</span>
              </div>
            )}

            <table>
              <tbody>
                <tr>
                  <td><Translate tKey="Education"/></td>
                  {education && <td>{education}</td> }
                </tr>

                <tr>
                  <td><Translate tKey="University name"/></td>
                  {university && <td>{university}</td>}
                </tr>

                <tr>
                  <td><Translate tKey="Degree"/></td>
                  {degree && <td>{degree}</td>}
                </tr>
              </tbody>
            </table>

            {description && <BlogContent content={description} className='blueToGray' /> }
            <div className={[b.justifyContentCenter, b.row].join(" ")}>
              {social_li && (
                <>
                  <div className={b.colLgAuto}>
                      <a href={social_li} target="_blank" rel="noreferrer" className={["blueToWhite",'author-button',b.fontWeightNormal].join(' ')}>
                        <Icon size={20} icon="LinkedIn-outline" className={[b.mr1, "icon-blog"].join(" ")}/>
                        {name} <Translate tKey="on LinkedIn"/>
                      </a>
                  </div>
                </>
              )}
            </div>
          </div>

          {blogs.length > 0 && mysqlId !== 2704 &&(
            <aside className={[b.mb4, b.pbLg5, b.mbXl5, "posts"].join(" ")}>
              <h2><Translate tKey="Recent user entries" /><span className="blue-name"> {name}</span> <Translate tKey="in Loando."/></h2>
              
              <Posts items={blogs} countItem={8} step={16}>
                {categories.nodes.map((category, i) => (
                  <article key={i} className={[b.card, 'post'].join(' ')}>
                    <Link to={resolveUrl(category.path, category.term_normalized)} className="post-link">
                      <img src={`/assets/categories/${category.mysqlId}.jpg`} alt={category.term} loading="lazy" style={{ maxWidth: 'none', width: '100%', maxHeight: '120px' }} />
                    </Link>

                    <div className="post-body">
                      <div>
                        <header>
                          <span className={["post-text",'font-size-10','post-text-color'].join(' ')}>25.11.2022</span>
                          <h3 className={[b.h6, b.fontWeightBold, b.mb3, b.mt1].join(' ')}>
                            <Link to={resolveUrl(category.path, category.term_normalized)} className={["post-link","post-heading-color"].join(' ')}>{category.header}</Link>
                          </h3>
                        </header>

                        <p className={['post-text', 'post-text-color', b.mb2].join(' ')}>
                          {category.lead}
                        </p>
                      </div>
                    </div>
                  </article>
                ))}
              </Posts>
            </aside>
          )}
        </div>
      </article>
    </Layout>
  );
};

export default author;

export const query = graphql`
  query QueryAuthor($mysqlId: Int!) {
    mysqlAuthor(mysqlId: { eq: $mysqlId }, blogs: {elemMatch: {promo_bonus: {eq: ""}, status: {eq: "publish"}, visible: {eq: 1}}}) {
      description
      name
      education
      degree
      social_li
      mysqlId
      university
      title
      mysqlImage {
        childImageSharp {
          gatsbyImageData(width: 275, height: 275)
        }
      }
      blogs {
        ...PostData
        promo_bonus
        external
      }
    }
    categories: allMysqlTerms(
      filter: {author_id: {eq: $mysqlId}},
      sort: {fields: cluster, order: ASC}
    ) {
      nodes {
        mysqlId
        term
        header
        lead
        term_normalized
        path
      }
    }
  }
`;
